import moment from 'moment'
moment.locale('pt-br')

export default class UtilsService {
    static formatData = (data) => moment(new Date(data)).startOf('day').format('L')
    static formatDataTime = (data) => new Date(data).toLocaleString('pt-br')
    static getWeekDay = (data) => new Date(data).toLocaleDateString('pt-br', { weekday: 'long' })
    static formatCalendar = (data) => moment(new Date(data)).format('DD/MM/YYYY [às] HH:mm (dddd)')
    static formatCPF = (cpf) => cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') || ''
    static protectCPF = (cpf) => cpf.replace(cpf.substring(4, 11), '*'.repeat(7))
}   