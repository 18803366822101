import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { router } from './routes';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from './vuex';
import VueMask from 'v-mask';
import money from "v-money";
import UtilsService from './service/utilsService';
import * as VueGoogleMaps from "vue2-google-maps";
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(VueMask);
Vue.use(VueAxios, axios);
Vue.use(money, { precision: 4 });

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAfrtV0BQ5k2GUJCgMaTsneZJ7XpmdRZeI",
    libraries: "places"
  }
});

Vue.filter('formatData', UtilsService.formatData)
Vue.filter('formatDataTime', UtilsService.formatDataTime)
Vue.filter('formatCPF', UtilsService.formatCPF)
Vue.filter('protectCPF', UtilsService.protectCPF)
Vue.filter('weekDay', UtilsService.getWeekDay)
Vue.filter('formatCalendar', UtilsService.formatCalendar)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')