<template>
  <v-app>

    <Drawer />

    <v-main>
      <v-sheet color="#eee">  
      </v-sheet>
      <router-view></router-view>
    </v-main>

    <v-snackbar v-model="snackbar" height="300" width="500" :color="cor">
      <template v-slot:action="{ attrs }">
        <v-btn outlined :to="{ name: 'contas' }" color="white">{{ mensagem }}</v-btn>
        <v-btn v-bind="attrs" @click="fechaLoading" text>Fechar</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    Drawer: () => import('./components/drawer/drawer.vue'),
  },
  data: () => ({
    initiallyOpen: [''],
    btn: true,
    group: null,
    drawer: true,
    snackbar: false,
    items: [
      { title: 'Home', icon: 'mdi-home-city' },
      { title: 'My Account', icon: 'mdi-account' },
      { title: 'Users', icon: 'mdi-account-group-outline' },
    ],
    mini: true,
  }),
  computed: {
    ...mapGetters(['loading', 'cor', 'mensagem', 'permissoes']),
    /*
    breadcrumbs(){
      let path = this.$route.path
      let arr     = path.split('/')
      let tamanho = arr.length
      let caminho = []
      if (tamanho> 1){
        let c = '' 
        let t = ''
        for(let i=0;i < tamanho ;i++){
          c = `/${arr[i]}`
          t = i == 0 ? 'home' : `${arr[i]}`
          caminho.push({ text: t, disabled: (tamanho == i + 1), href: c })
        }
      }
      return caminho
      
    },*/
    config() {
      return JSON.parse(localStorage.getItem('cfgu')) ? JSON.parse(localStorage.getItem('cfgu')) : {
        refresh: "", access: "", usuario: {

          first_name: '',
          nome: '',
          avatar: '',
          //trocar_senha:true,
          icons: ''

        }, apps: []
      }
    }
  },
  methods: {
    go(val) {
      this.$router.push({ name: val })
    },
    fechaLoading() {
      this.snackbar = false
      this.$store.dispatch('btnLoading', false)
      this.$store.dispatch('cor', '')
      this.$store.dispatch('mensagem', '')
    },
  },
  mounted() {
    if (this.config.trocar_senha && localStorage.getItem('tokenAccess')) {
      this.$store.dispatch('permissoes', 'contas/usuarios/permissoes/')
      this.$store.dispatch('btnLoading', false)
      this.snackbar = true
      this.$store.dispatch('btnLoading', true)
      this.$store.dispatch('cor', 'orange')
      this.$store.dispatch('mensagem', 'Troque a sua senha')
    }
  }
};

</script>
<style scoped>
@font-face {
  font-family: 'museosans';
  src: url('~@/assets/css/fonts/MUSEOSANS.OTF');
  font-style: normal;
  font-weight: 100;

}

html,
body {
  font-family: 'museosans';
}

#app {
  font-family: 'museosans';
}

.azul {
  background-color: '#0aabbe' !important
}

.v-application {
  background-color: #fff !important;
}

.theme--dark .v-application {
  background: #47A4AD;
  color: #4d4d4d;
}

.v-application .primary {
  background-color: #fff !important;
  border-color: #1976d2 !important;
}

.borda-azul {
  border-color: blue !important;
}

.verde-1 {
  color: #02b2b8;
}
</style>